import React, {memo} from 'react'

const Mark = memo(
  ({className = '', svgClassName = 'mh-50px'}: {className?: string; svgClassName?: string}) => {
    return (
      <span className={`svg-icon ${className}`}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24px'
          height='24px'
          viewBox='0 0 24 24'
          version='1.1'
          className={svgClassName}
        >
          <defs />
          <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
            <rect x='0' y='0' width='24' height='24' />
            <path
              d='M8,4 L16,4 C17.1045695,4 18,4.8954305 18,6 L18,17.726765 C18,18.2790497 17.5522847,18.726765 17,18.726765 C16.7498083,18.726765 16.5087052,18.6329798 16.3242754,18.4639191 L12.6757246,15.1194142 C12.2934034,14.7689531 11.7065966,14.7689531 11.3242754,15.1194142 L7.67572463,18.4639191 C7.26860564,18.8371115 6.63603827,18.8096086 6.26284586,18.4024896 C6.09378519,18.2180598 6,17.9769566 6,17.726765 L6,6 C6,4.8954305 6.8954305,4 8,4 Z'
              fill='#585671'
            />
          </g>
        </svg>
      </span>
    )
  }
)
export default Mark
