import React from 'react'
import {Rating} from '../../../../modules/types/ssl'

/**
 * BarChartAsset component displays a bar chart for SSL ratings
 * @param {Object} props - Component props
 * @param {Rating[]} props.data - Array of Rating objects to display in the chart
 * @returns {JSX.Element} Rendered bar chart
 */
const BarChartAsset = ({data}: {data: Rating[]}) => {
  // Create a copy of the data and remove the 4th element (index 3)
  const trustData = [...data]
  trustData.splice(3, 1)

  // Calculate the maximum chart width based on the highest 'finding' value
  // const maxChartWidth = Math.max(...trustData?.map((item) => +item.finding))

  return (
    <div className={`bg-body h-100 card p-2`}>
      <div className={`w-100 h-100 p-0 pt-4 d-flex flex-column align-items-center`}>
        {trustData?.map((item) => (
          <div key={item.title} className='d-flex flex-row align-items-center pt-3 pb-3 w-100 row'>
            <div className='d-flex justify-content-end fw-bolder col-3'>{item.title}</div>
            <div className='d-flex flex-row align-items-center col-6'>
              <div className='rounded-start-0 w-100 h-20px progress'>
                <div
                  className='d-flex align-items-center m-0 px-1 h-20px progress-bar'
                  role='progressbar'
                  style={{
                    height: 12,
                    // Calculate the width of the bar as a percentage of the maximum width
                    width: `${+item.finding }%`,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    // Set color based on the 'finding' value
                    backgroundColor: +item.finding > 80 ? '#00fa00' : '#ffff00',
                    color: '#000',
                    fontWeight: 'bolder',
                  }}
                >
                  {item.finding}%
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BarChartAsset

