import React, {memo, useEffect, useLayoutEffect, useState} from 'react'
import {Formik, Form, Field, FormikHelpers} from 'formik'
import * as Yup from 'yup'
import {Alert} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Node, useReactFlow} from '@xyflow/react'
import Tool from '../../images/Tool'
import Warning2 from '../../images/Warning2'
import {Tooltip} from 'react-tooltip'
import AiModal from './AiModal'
import axios from 'axios'
import usePermission from '../../../../Utils/usePermission'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {Modal as BootstrapModal} from 'bootstrap'
import {toast} from 'sonner'
import CodeEditor from './CodeEditor'
import YAML from 'js-yaml'


interface Field {
  name: string
  type: 'boolean' | 'number' | 'string' | 'yaml'
  inputType: 'number' | 'text' | 'select' | 'checkbox' | 'textarea'
  required: boolean
  label: string
  min?: number
  max?: number
  options?: Array<{label: string; value: string | number}>
}

interface Config {
  abstract_config?: {
    fields: Field[]
  }
  node_type_id: string
  ai_assisted: boolean
  ai_example?: string | null
  config?: Record<string, any>
}

interface DrawerConfigProps {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setOtherDrawer: React.Dispatch<React.SetStateAction<boolean>>
  node: (Node & Config) | null
}

interface FormValues {
  [key: string]: string | number | boolean
}

const DrawerConfig = memo(({show, setShow, setOtherDrawer, node}: DrawerConfigProps) => {
  const {updateNode} = useReactFlow()
  const intl = useIntl()
  const errorHandling = useErrorHandling()
  const {isPermission, getPermission} = usePermission()
  const [answer, setAnswer] = useState<{[key: string]: any} | null>(null)
  const [status, setStatus] = useState<'pending' | 'success' | 'failed'>('success')
  const [update, setUpdate] = useState(1)
  const [isFullScreen , setIsFullScreen]=useState<boolean>(false)
  let checkStatus: NodeJS.Timeout | undefined

  const closeModal = () => {
    const modalElement = document.getElementById('kt_modal_AI2')
    if (modalElement) {
      const modalInstance = BootstrapModal.getOrCreateInstance(modalElement)
      modalInstance.hide()
    }
  }

  /**
   * Fetches the current status of an AI chat request
   *
   * @param {string} id - Unique identifier for the chat request
   * @async
   * @description Checks the status of a pending AI request and updates component state
   */
  const fetchChatStatus = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/workflow/node-types/${node?.node_type_id}/ai-status/`
      )
      errorHandling(data.status) // Handle potential authentication errors
      if (data.status >= 200 && data.status < 300) {
        if (data.data.status === 'pending') {
          // Still processing
          return
        } else {
          clearInterval(checkStatus)
          if (data.data.status === 'success') {
            fetchChatList()
          } else {
            setStatus('failed')
            toast.error("Oops! The prompt doesn't seem relevant. Try another one!")
          }
        }
      } else {
        clearInterval(checkStatus)
        console.error(`Error fetching status from `)
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Fetches the results of an AI chat request
   *
   * @param {string} id - Unique identifier for the chat request
   * @async
   * @description Retrieves the generated content and updates component state
   */
  const fetchChatList = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/workflow/node-types/${node?.node_type_id}/ai-assist/`
      )
      errorHandling(data.status) // Handle potential authentication errors
      getPermission(data.status)

      if (data.status >= 200 && data.status < 300) {
        setAnswer(data.data)
        setStatus('success')
        closeModal()
        toast.success('Your config has been successfully created! Review it, and apply when ready.')
      } else {
        setStatus('failed')
        toast.error("Oops! The prompt doesn't seem relevant. Try another one!")
        clearInterval(checkStatus)
        console.error(
          `Error fetching data from ${process.env.REACT_APP_API_ENDPOINT}/nuclei/questions/`
        )
      }
    } catch (e) {
      setStatus('failed')
      toast.error("Oops! The prompt doesn't seem relevant. Try another one!")
      console.log(e)
    }
  }

  /**
   * Sends a message to the AI service
   *
   * @param {string} content - The content to be processed by the AI
   * @description Initiates an AI request and sets up status checking
   */
  const sendMessage = (content: string) => {
    console.log(node?.node_type_id)
    if (node?.node_type_id) {
      setStatus('pending')
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/workflow/node-types/${node?.node_type_id}/ai-assist/`,
          {
            message: content,
          }
        )
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            checkStatus = setInterval(() => {
              console.log('status')
              fetchChatStatus()
            }, 2000)
          } else {
            setStatus('failed')
            toast.error("Oops! The prompt doesn't seem relevant. Try another one!")
          }
          // Set up periodic status checking
        })
        .catch((e) => {
          setStatus('failed')
          toast.error("Oops! The prompt doesn't seem relevant. Try another one!")
          console.error(e)
          console.log('message error', e?.response?.data?.error)
        })
    }
  }

  const findInitialValues = (): FormValues => {
    if (!node?.abstract_config?.fields) return {}

    return node.abstract_config.fields.reduce((acc: FormValues, item: Field) => {
      if (node.config?.[item.name] !== undefined) {
        acc[item.name] = node.config[item.name]
        return acc
      }

      switch (item.type) {
        case 'number':
          acc[item.name] = item.min || 0
          break
        case 'boolean':
          acc[item.name] = false
          break
        default:
          acc[item.name] = ''
      }
      return acc
    }, {})
  }

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape(
        node?.abstract_config?.fields?.reduce((acc: Record<string, any>, field: any) => {
          let validator: Yup.Schema

          switch (field.type) {
            case 'number':
              validator = Yup.number()
                .typeError(intl.formatMessage({id: 'Must be a number'}))
                .min(field.min!, `${intl.formatMessage({id: 'Must be at least'})} ${field.min}`)
                .max(field.max!, `${intl.formatMessage({id: 'Must be at most'})} ${field.max}`)
              break
            case 'boolean':
              validator = Yup.boolean()
              break
            default:
              if (field?.format === 'yaml') {
                validator = Yup.string().test('is-valid-yaml', 'Invalid YAML syntax', (value) => {
                  try {
                    YAML.load(value || '') // Attempt to parse YAML
                    return true
                  } catch (error) {
                    return false // Invalid YAML
                  }
                })
              } else {
                validator = Yup.string()
              }
          }

          if (field.required) {
            validator = validator.required(intl.formatMessage({id: 'This field is required'}))
          }

          acc[field.name] = validator
          return acc
        }, {}) || {}
      ),
    [node?.abstract_config?.fields, intl]
  )

  const handleSubmit = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
    if (!node?.id) {
      toast.error('The configuration could not be applied successfully. Please try again.')
      return}

    updateNode(node.id, {
      ...node,
      config: {...values},
    } as Node)
    setSubmitting(false)
    setShow(false)
    toast.success('Configuration applied successfully!')
  }
  useEffect(() => {
    setUpdate(update + 1)
  }, [node, answer])
  useEffect(() => {
    setAnswer(null)
  }, [node])
  useEffect(() => {
    setIsFullScreen(false)
  }, [show , node])

  return (
    <>
      <div
        id='kt_Drawer_Config'
        className={`bg-body drawer drawer-end overflow-visible ${
          show ? 'drawer-on' : ''
        } work_drawer bg-secondary card`}
        style={{zIndex: show ? 9 : 10, minWidth:isFullScreen? 800:380, maxWidth: isFullScreen? 800:380}}
      >
        <div className='node_item'>
          <div className='h-100 drawer-content'>
            <div className='border-0 h-100 card'>
              <div className='p-4 h-100 card-body'>
                {node && node?.abstract_config ? (
                  <Formik
                    key={update}
                    initialValues={answer || findInitialValues()}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({errors, touched, isSubmitting, setFieldError, setFieldValue}) => (
                      <Form className='space-y-6'>
                        <div className='text-end d-flex align-items-center gap-5'>
                          <button
                            type='submit'
                            disabled={isSubmitting}
                            className={`btn  btn-success `}
                          >
                            {intl.formatMessage({id: 'Save'})}
                          </button>
                          {node.ai_assisted && (
                            <button
                              data-bs-toggle={'modal'}
                              data-bs-target={`#kt_modal_AI2`}
                              disabled={isSubmitting}
                              className={`btn  btn-primary `}
                              onClick={(e) => {
                                e.preventDefault()
                              }}
                            >
                              {intl.formatMessage({id: 'Configuration with AI'})}
                            </button>
                          )}
                        </div>
                        <div className='w-100 mt-5'>
                          {node?.abstract_config?.fields?.map((field: Field, index) => (
                            <div key={field.name} className='mb-4'>
                              <label htmlFor={field.name} className='form-label fw-bolder fs-6'>
                                {field.label}
                                {/* <div
                                  data-tooltip-id={`${index}`}
                                  data-tooltip-content={field.label}
                                  style={{cursor: 'pointer', alignSelf: 'start', width: '1.7rem'}}
                                >
                                  <Warning2
                                    className='image_change_theme2'
                                    svgClassName='info-icon'
                                  />
                                </div>
                                <Tooltip
                                  id={`${index}`}
                                  style={{
                                    borderRadius: 5,
                                    maxWidth: 200,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    zIndex: 10001,
                                  }}
                                /> */}

                                {field.required && <span className='text-danger ms-1'>*</span>}
                              </label>

                              {field.inputType === 'select' ? (
                                <Field
                                  as='select'
                                  name={field.name}
                                  className={`form-control ${
                                    errors[field.name] && touched[field.name] ? 'is-invalid' : ''
                                  }`}
                                >
                                  <option value=''></option>
                                  {field?.options?.map((item, ind) => (
                                    <option key={ind} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Field>
                              ) : field.inputType === 'checkbox' ? (
                                <div className='form-check form-switch'>
                                  <Field
                                    type='checkbox'
                                    name={field.name}
                                    className='form-check-input'
                                  />
                                </div>
                              ) : field.inputType === 'textarea' || field.type === 'yaml' ? (
                                // <Field
                                //   as='textarea'
                                //   type={'textarea'}
                                //   name={field.name}
                                //   className={`form-control ${
                                //     errors[field.name] && touched[field.name] ? 'is-invalid' : ''
                                //   }`}
                                //   render={<CodeEditor answer=''   />}
                                // />
                                <Field name={field.name} className='h-100'>
                                  {({field, form, meta}: any) => {
                                    return (
                                      <div className='h-500px'>
                                        <CodeEditor
                                        isFullScreen={isFullScreen}
                                        setIsFullScreen={setIsFullScreen}
                                          name={field.name}
                                          setAnswer={setFieldValue}
                                          answer={field.value}
                                        />
                                      </div>
                                    )
                                  }}
                                </Field>
                              ) : (
                                <Field
                                  type={field.inputType}
                                  name={field.name}
                                  min={field.min}
                                  max={field.max}
                                  className={`form-control ${
                                    errors[field.name] && touched[field.name] ? 'is-invalid' : ''
                                  }`}
                                />
                              )}

                              {errors[field.name] && touched[field.name] && (
                                <div className='invalid-feedback d-block'>
                                  {String(errors[field.name])}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div className='py-4 text-center text-gray-600'>
                    <i className='mb-3 fa-info-circle fas fs-2'></i>
                    <p className='mb-0'>
                      {intl.formatMessage({
                        id: 'To display the configuration, first select the desired node.',
                      })}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <button
          className='bg-secondary work_drawer_button_config'
          style={{border: 'none'}}
          onClick={() => {
            setShow(!show)
            if (!show) {
              setOtherDrawer(false)
            }
          }}
        >
          <div className='px-0 w-auto aside-toggle btn btn-active-color-primary btn-icon'>
            <Tool className='svg-icon-2x svg-icon-primary' />
          </div>
        </button>
      </div>
      <AiModal generate={sendMessage} status={status} example={node?.ai_example} />

    </>
  )
})

export default DrawerConfig
