import React, {memo, useState} from 'react'
import axios from 'axios'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {KTSVG} from '../../../_metronic/helpers'
import {Button} from 'react-bootstrap'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'
import {toast} from 'sonner'
import {Plan, PlanType} from '../../modules/types/plans'
import {useNavigate} from 'react-router-dom'
import {Modal as BootstrapModal} from 'bootstrap'
interface Props {
  details: null | Plan
  setDetails: React.Dispatch<React.SetStateAction<Plan | null>>
}

/**
 * Modal component for displaying and managing plan details and payment
 * @param {Props} props - Component props
 */
const Modal = memo(({details, setDetails}: Props) => {
  const [loadingDiscount, setLoadingDiscount] = useState(false)
  const [loadingPay, setLoadingPay] = useState(false)
  const [copen, setCopen] = useState('')
  const [newPrice, setNewPrice] = useState<number | null>(null)
  const [savedCopen, setSavedCopen] = useState('')
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const navigate = useNavigate()

  /**
   * Creates the body for the payment request
   * @returns {Object} The payment request body
   */
  function createBodyPay () {
    if (savedCopen) {
      return {
        plan: details?.id,
        payment_method: 'zarinpal',
        discount_code: savedCopen,
      }
    } else {
      return {
        plan: details?.id,
        payment_method: 'zarinpal',
      }
    }
  }

  /**
   * Initiates the payment process
   */
  function PostPay () {
    setLoadingPay(true)

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/subscription/subscribe/`, createBodyPay())
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          PostPayId(res.data.payment_id)
        } else {
          toast.error(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
          setLoadingPay(false)
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data?.error)
        toast.error(
          e.response.data.detail.replace(/[\[\]"']/g, ' ') ||
            intl.formatMessage({id: 'Sorry, an error has occurred'})
        )
        setLoadingPay(false)
      })
  }

  /**
   * Processes the payment with the given ID
   * @param {string} id - The payment ID
   */
  function PostPayId (id: string) {
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/subscription/pay/`, {
        payment: id,
      })
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          if (res.status === 201) {
            const modalElement = document.getElementById('kt_modal_edit')
            if (modalElement) {
              const modalInstance = BootstrapModal.getOrCreateInstance(modalElement)
              modalInstance.hide()
            }
            navigate('/payment/verify_success')
            setDetails(null)
            setCopen('')
            setNewPrice(null)
          } else {
            const URL = res.data.payment_url
            if (URL) {
              window.location.href = URL
            }
          }
        } else {
          toast.error(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data)
        toast.error(
          e.response.data.detail.replace(/[\[\]"']/g, ' ') ||
            intl.formatMessage({id: 'Sorry, an error has occurred'})
        )
      })
      .finally(() => {
        setLoadingPay(false)
      })
  }

  /**
   * Applies the discount code
   */
  function applyDiscount () {
    setLoadingDiscount(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/plans/apply-discount/`, {
        plan: details?.id,
        discount_code: copen,
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setNewPrice(res.data.discounted_price)
          setSavedCopen(copen)
        } else {
          console.log('err', res?.data?.detail)
          toast.error(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        console.log('message \n', e.response.data.detail)
        toast.error(
          e.response.data.detail.replace(/[\[\]"']/g, ' ') ||
            intl.formatMessage({id: 'Sorry, an error has occurred'})
        )
      })
      .finally(() => {
        setLoadingDiscount(false)
      })
  }
  console.log('p', details?.discounted_price)
  return (
    <>
      <div
        className='fade modal'
        id='kt_modal_edit'
        style={{backdropFilter: 'blur(3px)'}}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setDetails(null)
          setCopen('')
          setNewPrice(null)
        }}
      >
        <div
          className='modal-dialog'
          style={{minWidth: '300px ', top: '100px'}}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'> {'1403/08/20 10:30'}</h3>
              <div
                className='btn btn-active-light-primary btn-icon btn-sm ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  setDetails(null)
                  setCopen('')
                  setNewPrice(null)
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='image_change_theme2 svg-icon-2'
                />
              </div>
            </div>

            <div className='modal-body'>
              <div className='d-flex flex-column align-items-center gap-5'>
                <h1 className='text-primary'>{details?.name}</h1>

                {/* Display the price, considering discounts */}
                <div>
                  <h5 className='text-center text-muted' style={{textDecoration: 'line-through'}}>
                    {newPrice !== null
                      ? FormatAndConvertAmount(details?.discounted_price || details?.price || 0)
                      : null}
                  </h5>
                  <h3>
                    {newPrice !== null
                      ? FormatAndConvertAmount(newPrice)
                      : details?.discounted_price
                      ? FormatAndConvertAmount(details?.discounted_price)
                      : FormatAndConvertAmount(details?.price || '')}
                  </h3>
                </div>

                {/* Discount code input and apply button */}
                <div className='d-flex gap-1 mb-10 fv-row'>
                  <input
                    onChange={(e) => {
                      setCopen(e.target.value)
                    }}
                    value={copen}
                    placeholder={intl.formatMessage({id: 'Discount code'})}
                    className={'form-control form-control-lg form-control-solid'}
                    autoComplete='off'
                  />
                  <Button disabled={!copen || loadingDiscount} onClick={applyDiscount}>
                    {intl.formatMessage({id: 'Use'})}
                  </Button>
                </div>
                {process.env.REACT_APP_MODE === 'demo' && (
                  <p className='text-danger'>
                    {intl.formatMessage({id: 'This feature is not available in demo mode'})}
                  </p>
                )}
                {/* Payment button */}
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-success'
                  disabled={process.env.REACT_APP_MODE === 'demo' || loadingPay}
                  onClick={PostPay}
                >
                  {intl.formatMessage({id: 'payment'})}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default Modal
