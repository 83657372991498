import React, {memo} from 'react'

const OpenLock = memo(
  ({className = '', svgClassName = 'mh-50px'}: {className?: string; svgClassName?: string}) => {
    return (
      <span className={`svg-icon ${className}`}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24px'
          height='24px'
          viewBox='0 0 24 24'
          version='1.1'
          className={svgClassName}
        >
          <defs />
          <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
            <mask fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g />
            <path
              d='M15.6274517,4.55882251 L14.4693753,6.2959371 C13.9280401,5.51296885 13.0239252,5 12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L14,10 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C13.4280904,3 14.7163444,3.59871093 15.6274517,4.55882251 Z'
              fill='#585671'
            />
          </g>
        </svg>
      </span>
    )
  }
)
export default OpenLock
