import React, {useState, useRef, useLayoutEffect} from 'react'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import {DetailsDrawer} from './components/DetailsDrawer'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import Visibel from '../images/Visibel'
import Sort from '../images/Sort'
import {useNavigate} from 'react-router-dom'
import ConvertDate from '../../../Utils/ConvertDate'
import {RootState} from '../../../setup/redux/Store'
import {
  FilterOptionVulnerabilities,
  FilterSelectedVulnerabilities,
  SearchVulnerabilities,
  SetDataVulnerability,
  SortVulnerabilities,
} from '../../modules/auth'
import {useIntl} from 'react-intl'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {VUL_DATA} from '../../modules/auth/redux/DEMO_DATA'
import usePermission from '../../../Utils/usePermission'
import SearchAndSort from '../components/SearchAndSort'
import {SelectedType} from '../../modules/types/filters'

const SortOptions = [
  {value: 'host', name: 'Host'},
  {value: 'severity', name: 'Severity'},
  {value: 'timestamp', name: 'Date'},
]
/**
 * Vulnerabilities component for displaying and managing vulnerability data
 */
export const Vulnerabilities = () => {
  const [loading, setLoading] = useState(false)
  const lang = useLang()?.lang.selectedLang
  const errorHandling = useErrorHandling()
  const {isPermission, getPermission} = usePermission()
  const [id, setId] = useState<null | string>(null)
  const [sortState, setSortState] = useState('')
  const pageRender = useRef(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const vulnerabilityData = useSelector((state: RootState) => state?.root?.data?.dataVulnerability)
  const user = useSelector((state: RootState) => state?.root?.user)
  const sort = useSelector((state: RootState) => state.root.filterOption.vulnerabilities)
  const filterOption = useSelector(
    (state: RootState) => state?.root?.filterOption?.vulnerabilities.options
  )
  const selected = useSelector(
    (state: RootState) => state?.root?.filterOption?.vulnerabilities.selected
  )
  const search = useSelector((state: RootState) => state?.root?.filterOption.vulnerabilities.search)

  /**
   * Handle pagination click event
   * @param {Object} event - The event object containing the selected page
   */
  const handlePageClick = (event: {selected: number}) => {
    fetchVulnerabilityData({query: `?page=${event.selected + 1}`})
  }

  /**
   * Fetch filter options for vulnerabilities
   */
  const getFilterOptions = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/filter/`)
      errorHandling(data.status) // Logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(FilterOptionVulnerabilities(data.data))
      } else {
        console.error(
          `Error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/filter/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Generates sorting query parameters for API request
   *
   * @param {string} sortProperty - Sorting property to be applied
   * @returns {string} Formatted sorting query string
   */
  function createPropsSort (sortProps: string) {
    if (sortProps) {
      return sortProps
    }
    if (sort.sortAsc) {
      return `&ordering=${sort.sortAsc}`
    } else if (sort.sortDes) {
      return `&ordering=${sort.sortDes}`
    }
    return ''
  }
  /**
   * Constructs search query parameters from current search state
   *
   * @returns {string} Concatenated search query string
   */
  function createPropsSearch () {
    return search.map((item) => `&${item.name}=${item.value}`).join('')
  }
  function createPropsFilter (props?: null | SelectedType) {
    if (props) {
      const keys = Object.keys(props)
      const text = keys.map((key) => {
        if (props[key] && props[key].length) {
          return `&${key}=${props[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    } else {
      const keys = Object.keys(selected || {})
      const text = keys.map((key) => {
        if (selected?.[key] && selected[key].length) {
          return `&${key}=${selected[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    }
  }
  const fetchVulnerabilityData = async ({
    query = '?page=1',
    filterProps = null,
    sort = '',
  }: {
    query?: string
    filterProps?: null | SelectedType
    sort?: string
  }) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/${query}${createPropsFilter(
          filterProps
        )}${createPropsSort(sort)}${createPropsSearch()}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataVulnerability(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/${query}${filterProps} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (vulnerabilityData?.expireTime && vulnerabilityData.expireTime >= nowDate) {
    } else {
      fetchVulnerabilityData({})
    }
    if (!filterOption) {
      getFilterOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DetailsDrawer id={id} setId={setId} />
      <>
        <div
          className={`row d-flex flex-row  mt-5 overflow-hidden position-relative ${
            loading && 'skeleton'
          }`}
        >
          {isPermission()}
          <div className='w-100 h-100'>
            <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
              {/* start title */}
              <div className='d-flex flex-row justify-content-between align-items-start pt-2 w-100 pe-3'>
                <h3 className=' '>{intl.formatMessage({id: 'Vulnerabilities'})}</h3>
              </div>
              {/* end title */}
              {/* start filters */}
              {/* 
              <div className='d-flex gap-5 my-10'>
                <div className='w-100'>
                  <label className='form-label fw-bold'>
                    {intl.formatMessage({id: 'Severity Filter'})}:
                  </label>

                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Severity Filter'
                      data-allow-clear='true'
                      value={selected?.['severity'] || ''}
                      onChange={(e) => {
                        selectedFilter('severity', e.target.value)
                      }}
                    >
                      <option value=''>{intl.formatMessage({id: 'No filter'})}</option>
                      {filterOption &&
                        filterOption?.severity?.map((item: any, ind: number) => (
                          <option key={ind} style={{textTransform: 'capitalize'}} value={item.name}>
                            {intl.formatMessage({id: item.name})}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className='w-100'>
                  <label className='form-label fw-bold'>
                    {intl.formatMessage({id: 'Sensor Filter'})}:
                  </label>

                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Sensor Filter'
                      data-allow-clear='true'
                      value={selected?.['sensor'] || ''}
                      onChange={(e) => {
                        selectedFilter('sensor', e.target.value)
                      }}
                    >
                      <option value={''}>{intl.formatMessage({id: 'No filter'})}</option>
                      {filterOption &&
                        filterOption?.sensor?.map((item: any, ind: number) => (
                          <option key={ind} style={{textTransform: 'capitalize'}} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className='w-100'>
                  <label className='form-label fw-bold'>
                    {intl.formatMessage({id: 'Host Filter'})}:
                  </label>

                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Host Filter'
                      data-allow-clear='true'
                      value={selected?.['domain'] || ''}
                      onChange={(e) => {
                        selectedFilter('domain', e.target.value)
                      }}
                    >
                      <option value={''}>{intl.formatMessage({id: 'No filter'})}</option>
                      {filterOption &&
                        filterOption?.domain?.map((item: any, ind: number) => (
                          <option key={ind} style={{textTransform: 'capitalize'}} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className='w-100'>
                  <label className='form-label fw-bold'>{intl.formatMessage({id: 'Search'})}</label>

                  <div>
                    <input
                      type='text'
                      className='form-control form-control-solid mw-300px w-100 text-gray-700'
                      placeholder={intl.formatMessage({id: 'Search'})}
                      value={search}
                      onChange={(e) => {
                        handelSearch(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <SearchAndSort
                className={'mb-5 mw-75'}
                search={search}
                setSearch={SearchVulnerabilities}
                sort={sort}
                setSort={SortVulnerabilities}
                getData={fetchVulnerabilityData}
                sortOption={SortOptions}
                searchOption={Object.keys(filterOption || {})}
              />
              {/* end filters */}

              {/* start titles */}
              <div className='m-0 p-0 w-100 pe-3'>
                <div className='d-flex align-items-center bg-gray-400 mb-4 p-1 rounded-1 fw-bold w-100 fs-5'>
                  <div className='p-1 w-50 h-auto ps-2'> {intl.formatMessage({id: 'Title'})} </div>
                  <div className='d-flex align-items-center p-1 w-100px h-auto ms-3 pointer ps-4 min-w-100px '>
                    {' '}
                    <span>{intl.formatMessage({id: 'Severity'})} </span>{' '}
                  </div>
                  <div className='d-flex align-items-center p-1 w-25 h-auto ms-3 pointer vul_target'>
                    <span>{intl.formatMessage({id: 'Sensor'})} </span>
                  </div>
                  <div className='d-flex align-items-center p-1 w-25 h-auto ms-3 pointer vul_target'>
                    <span>{intl.formatMessage({id: 'Target'})} </span>
                  </div>
                  <div className='d-flex align-items-center p-1 w-25 h-auto ms-3 pointer'>
                    <span>{intl.formatMessage({id: 'Affected Asset'})} </span>
                  </div>
                  <div className='d-flex align-items-center p-1 w-25 h-auto ms-3 pointer'>
                    <span>{intl.formatMessage({id: 'Last Seen'})}</span>
                  </div>

                  <div className='d-flex p-0 w-50px h-auto ms-2 pe-3'>
                    {' '}
                    {intl.formatMessage({id: 'View'})}{' '}
                  </div>
                </div>
              </div>
              {/* end titles */}
              {/* start body */}

              <div
                className={`w-100 h-100 d-flex flex-column gap-3 overflow-auto pe-3 m-0 min-h-200px`}
              >
                {(!vulnerabilityData?.data?.results?.length && !user?.is_user_scan
                  ? VUL_DATA.results
                  : vulnerabilityData?.data?.results
                )?.map((item, index: number) => (
                  <div
                    style={
                      !vulnerabilityData?.data?.results?.length
                        ? {filter: 'blur(3px)', cursor: 'pointer', minHeight: 64}
                        : {cursor: 'pointer', minHeight: 64}
                    }
                    key={item.id}
                    onClick={() => {
                      if (vulnerabilityData?.data?.results?.length) setId(item.id)
                    }}
                    className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex align-items-center  hover_Costume`}
                  >
                    <div className='d-flex align-items-center p-1 w-100'>
                      <div className='d-flex align-items-center p-1 w-50 h-auto text-nowrap text_hide_width'>
                        {' '}
                        {lang === 'fa' ? item?.title_fa || 'منتظر داده باشید' : item.title}{' '}
                      </div>
                      <div className='d-flex flex-row align-items-center p-1 min-w-100px w-100px h-auto text-nowrap text-capitalize ms-3 text_hide_width'>
                        <div className='d-flex justify-content-end'>
                          <div
                            className={`w-15px h-15px me-2 rounded-3 border-i${item.severity} border border-2 `}
                          />
                        </div>
                        {lang === 'fa' ? item?.severity_fa || 'منتظر داده باشید' : item.severity}
                      </div>
                      <span
                        className='d-flex flex-row align-items-center p-1 w-25 h-auto text-nowrap ms-3 text_hide_width'
                        style={{direction: 'ltr'}}
                      >
                        {item.sensor}
                      </span>
                      <span
                        className='d-flex flex-row align-items-center p-1 w-25 h-auto text-nowrap ms-3 text_hide_width'
                        style={{direction: 'ltr'}}
                      >
                        {item.target}
                      </span>
                      <div className='d-flex align-items-center p-1 w-25 h-auto text-nowrap ms-3 text_hide_width'>
                        {item.host || item.ip}
                      </div>
                      <div className='d-flex align-items-center p-1 w-25 h-auto text-nowrap ms-3 text_hide_width'>
                        <ConvertDate date={item.timestamp} jalali={item.timestamp_jalali} />
                      </div>
                      <button
                        className={`w-50px border border-0 text-white bg-gray-200 p-0 ms-2 pe-3 d-flex-justify-content-center visible_icon`}
                        onClick={() => {
                          if (vulnerabilityData?.data?.results?.length)
                            navigate({
                              pathname: '/vulnerability-details',
                              search: `?id=${item.id}`,
                            })
                        }}
                      >
                        <Visibel className='image_change_theme2 ms-2 svg-icon svg-icon-2x svg-icon-white' />
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              {/* end body */}
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end bg-body pb-4 pe-4'>
          <ReactPaginate
            nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={vulnerabilityData?.data?.total_pages || 1}
            forcePage={
              vulnerabilityData?.data?.current_page ? vulnerabilityData?.data?.current_page - 1 : 0
            }
            previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        </div>
      </>
    </>
  )
}
