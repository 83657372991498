import React, {memo, useEffect, useState} from 'react'
import './toltip.scss'
import {Scan} from '../../../../modules/types/asset'
import ConvertDate from '../../../../../Utils/ConvertDate'
import {Tooltip as ReactTooltip} from 'react-tooltip'

/**
 * Timeline component displays a series of data points as a timeline
 * @param {Object} props - Component props
 * @param {Data[]} props.data - Array of data points to display in the timeline
 */
const Timeline = memo(function ({data}: {data: Scan[]}) {
  const [newData, setNewData] = useState<Scan[]>([])
  useEffect(() => {
    const copy = structuredClone(data)
    const arr2 = new Array(25 - copy.length).fill({start_time: null, id: 'ma'})
    const combinedArray = [...copy, ...arr2]
    setNewData(combinedArray)
  }, [data])
  return (
    <>
      <div className='d-flex justify-content-between w-100 h-40px'>
        {newData?.map((item, index) => (
          <div
            className='rounded-pill h-100 tooltip_tt'
            key={index}
            style={{backgroundColor: '#fff', width: `calc(100% / ${newData.length * 2})`}}
          >
            {/* Timeline item */}
            <div
              className='rounded-pill w-100 h-100 timeline_hover'
              data-tooltip-id={`my-tooltip-${index}`}
              style={{
                backgroundColor: item.start_time ? 'green' : 'gray',
              }}
            />

            {/* Tooltip */}
            {item.start_time && (
              <ReactTooltip
                id={`my-tooltip-${index}`}
                style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
                children={
                  <span>
                    <ConvertDate date={item.start_time} />
                  </span>
                }
              />
            )}
          </div>
        ))}
      </div>
    </>
  )
})

export default Timeline
