import MonacoEditor from '@monaco-editor/react'
import * as yaml from 'js-yaml'
import {useThemeMode} from '../../../../_metronic/partials'
import SizeUp from '../../images/SizeUp'
import SizeDown from '../../images/SizeDown'

/**
 * CodeEditor component for YAML syntax editing and validation
 *
 * @component
 * @param {Object} props - Component properties
 * @param {Function} props.setError - Function to set error state
 * @param {Function} props.setAnswer - Function to update answer state
 * @param {string} props.answer - Current answer/content in the editor
 *
 * @returns {React.ReactElement} Monaco Editor component with YAML validation
 *
 * @example
 * <CodeEditor
 *   setError={setErrorHandler}
 *   setAnswer={setAnswerHandler}
 *   answer={currentAnswer}
 * />
 */
export default function CodeEditor ({
  setAnswer,
  answer,
  name,
  isFullScreen,
  setIsFullScreen,
}: {
  isFullScreen: boolean
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>
  name: string
  setAnswer: (field: string, value: any, shouldValidate?: boolean) => Promise<void | any>
  answer: string
}) {
  // Get current theme mode from Metronic theme hook
  const theme = useThemeMode().mode

  /**
   * Handles editor content changes with YAML syntax validation
   *
   * @param {string|undefined} value - New editor content
   */
  const handleEditorChange = (value: string | undefined) => {
    // try {
    //   // Attempt to parse YAML to validate syntax
    //   yaml.load(value || '')
    //   setError(name,undefined)
    // } catch (e) {
    //     console.log('ffff')
    //   // Set error if YAML syntax is incorrect
    //     setError(name,'YAML syntax is incorrect')
    // }

    // Update answer state with new content
    setAnswer(name, value || '')
  }

  return (
    <>
      <div className={`w-100 h-100 ${!isFullScreen?'form_code_editor':'form_code_editor_full'} position-relative `}>
        <div
          onClick={() => {
            setIsFullScreen(!isFullScreen)
          }}
          className='svg_config_code_editor'
        >
          {isFullScreen ? (
            <SizeDown className='position-absolute   image_change_theme2' />
          ) : (
            <SizeUp className='position-absolute   image_change_theme2' />
          )}
        </div>
        <MonacoEditor
          className='min-h-100'
          height='100%'
          language='yaml'
          value={answer}
          onChange={(e) => handleEditorChange(e)}
          theme={theme === 'dark' ? 'vs-dark' : 'light'}
          loading={<div className='h-100 w-100 skeleton'></div>}
          options={{
            minimap: {
              enabled: false,
            },
            lineNumbers: isFullScreen?"on":'off',
          }}
        />
      </div>
    </>
  )
}
