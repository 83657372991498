import {memo, useLayoutEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {Table, Badge} from 'react-bootstrap'
import axios from 'axios'
import Trash from '../../images/Trash'
import Edit from '../../images/Edit'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import usePermission from '../../../../Utils/usePermission'
import CreateWorkflowModal from './CreateWorkflowModal'
import ReactPaginate from 'react-paginate'
import {toast} from 'sonner'

interface Table {
  id: string
  name: string
  description: string
  created: string
  created_jalali: string
  is_draft: boolean
}

const WorkflowTable = memo(() => {
  const navigate = useNavigate()
  const intl = useIntl()
  const errorHandling = useErrorHandling()
  const {getPermission, isPermission} = usePermission()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Table[] | null>(null)
  const handlePageClick = (event: {selected: number}) => {
    // fetchFlowList(event.selected+1)
  }

  const fetchFlowList = async (page = 1) => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/workflow/`)
      errorHandling(response.status)
      getPermission(response.status)

      if (response.status >= 200 && response.status < 300) {
        setData(response.data)
      }
    } catch (error) {
      console.error('Error fetching workflow data:', error)
    } finally {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    fetchFlowList()
  }, [])

  const handleEdit = (id: string) => {
    navigate({
      pathname: '/workFlow-edit',
      search: `?id=${id}`,
    })
  }

  const handleDelete = async (id: string) => {
    setLoading(true)
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/workflow/${id}/`)
      errorHandling(response.status)
      if (response.status >= 200 && response.status < 300) {
        toast.success('Workflow deleted successfully')
        fetchFlowList()
      }
    } catch (error) {
      toast.error('Error deleting workflow')
      console.error('Error fetching workflow data:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className={`w-100 h-100 position-relative ${loading && 'skeleton'}`}>
        {isPermission()}
        <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
          <div className='d-flex flex-row justify-content-between align-items-center py-3 pt-2 w-100 h-100px'>
            <h3 className='m-0 p-0'>{intl.formatMessage({id: 'Workflows'})}</h3>
            <button
              type='button'
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_workflow'
            >
              {intl.formatMessage({id: 'Create New WorkFlow'})}
            </button>
          </div>

          <div className='m-0 p-0 w-100 pe-3'>
            <div className='d-flex align-items-center bg-gray-400 mb-4 p-1 rounded-1 fw-bold w-100 fs-5'>
              <div className='p-1 w-300px h-auto ps-2'> {intl.formatMessage({id: 'Name'})} </div>
              <div className='d-flex align-items-center p-1 w-75 h-auto ms-3 pointer'>
                <span>{intl.formatMessage({id: 'Description'})} </span>
              </div>

              <div className='d-flex justify-content-center align-items-center p-1 w-200px h-auto ms-3 pointer'>
                <span>{intl.formatMessage({id: 'Status'})}</span>
              </div>

              <div className='d-flex justify-content-center p-0 w-100px min-w-85px h-auto ms-2'>
                {intl.formatMessage({id: 'Action'})}
              </div>
            </div>
          </div>

          <div className={`w-100 h-100 overflow-auto pe-3 m-0`}>
            {data?.map((item: any, index: number) => (
              <div
                key={item.id}
                className={`w-100 m-0 p-0  bg-gray-200 rounded-1 d-flex align-items-center mb-3 vulnerability_box_item`}
              >
                <div className='d-flex align-items-center p-1 w-100'>
                  <div className='d-flex align-items-center p-1 w-300px h-auto'> {item?.name} </div>
                  <div className='d-flex flex-row align-items-center p-1 w-75 h-auto text-capitalize ms-3'>
                    {item?.description}
                  </div>
                  <div className='d-flex justify-content-center align-items-center p-1 w-200px h-auto ms-3'>
                    <Badge bg={item.is_draft ? 'warning' : 'success'} className='px-3 py-2'>
                      {intl.formatMessage({
                        id: item.is_draft ? 'Not saved' : 'Saved',
                      })}
                    </Badge>
                  </div>
                  <div className='d-flex justify-content-center align-items-center gap-3 p-1 w-100px h-auto ms-3'>
                    <button
                      className={` border border-0 text-white bg-gray-200 p-0 d-flex-justify-content-center visible_icon`}
                      onClick={() => {
                        handleEdit(item.id)
                      }}
                      // disabled
                    >
                      <Edit className='svg-icon svg-icon-2x svg-icon-primary' />
                    </button>
                    <button
                      className={`border border-0 text-white bg-gray-200 p-0  d-flex-justify-content-center visible_icon`}
                      onClick={() => {
                        toast.info(intl.formatMessage({id: 'delete scan title'}), {
                          className: 'sonner',
                          closeButton: true,
                          duration: 10000,
                          action: {
                            label: intl.formatMessage({id: 'Delete'}),
                            onClick: () => {
                              handleDelete(item.id)
                            },
                          },
                        })
                      }}

                    >
                      <Trash className='svg-icon svg-icon-2x svg-icon-danger' />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='d-flex justify-content-end bg-body pt-2 pb-4 border-top pe-4'>
            <ReactPaginate
              nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={1}
              forcePage={0}
              previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
      <CreateWorkflowModal />
    </>
  )
})

export default WorkflowTable
