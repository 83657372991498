/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {memo, useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'

/**
 * Maps grade letter to numerical value for chart rendering
 * @param {string} percent - Grade letter (A, B, C, D)
 * @returns {number} Corresponding numerical value for chart
 */
const getAmount = (percent: string) => {
  switch (true) {
    case percent === 'A':
      return 90 // Highest grade, 90% representation
    case percent === 'B':
      return 70 // Second highest grade, 70% representation
    case percent === 'C':
      return 50 // Middle grade, 50% representation
    case percent === 'D':
      return 30 // Low grade, 30% representation
    default:
      return 10 // Lowest or undefined grade, 10% representation
  }
}

/**
 * Determines color based on grade letter for visual representation
 * @param {string} percent - Grade letter (A, B, C, D)
 * @returns {string} Corresponding color hex code
 */
const getColor = (percent: string) => {
  switch (true) {
    case percent === 'A':
      return '#008000' // Green for excellent performance
    case percent === 'B':
      return '#0000FF' // Blue for good performance
    case percent === 'C':
      return '#FFFF00' // Yellow for average performance
    case percent === 'D':
      return '#FF7F7F' // Light red for below average performance
    default:
      return '#FF0000' // Red for poor or undefined performance
  }
}

/**
 * HalfCircleChart component renders a half-circle radial bar chart
 * @component
 * @param {Object} props - Component properties
 * @param {string} [props.title='Total Grade'] - Chart title
 * @param {string|null|undefined} [props.percent] - Grade percentage
 */
const HalfCircleChart = memo(
  ({title = 'Total Grade', percent}: {title: string; percent: string | null | undefined}) => {
    // Retrieve user data from Redux store
    const user = useSelector((state: RootState) => state.root.user)

    // Reference for chart container
    const chartRef = useRef(null)

    // Dynamically determine chart color based on grade
    const color = getColor(percent || 'A')

    // Calculate chart fill percentage
    const amount = getAmount(percent || 'A')

    useEffect(() => {
      // Prevent rendering if chart reference is not available
      if (!chartRef.current) {
        return
      }

      // Create and render ApexChart instance
      const chart = new ApexCharts(chartRef.current, option2)
      if (chart) {
        chart.render()
      }

      // Cleanup chart on component unmount
      return () => {
        if (chart) {
          chart.destroy()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, percent])

    // Configuration object for ApexCharts radial bar
    const option2 = {
      chart: {
        type: 'radialBar', // Specify radial bar chart type
        height: 250,
        width: 250,
        offsetY: -20,
        offsetX: 0,
      },
      series: [amount], // Data series for chart
      colors: [color], // Dynamic color based on grade
      // labels: [title],  // Chart label
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,

          dataLabels: {
            show: true,
            name: {
              offsetY: 10,
              show: false,
              color: 'var(--bs-gray-700)',
              fontSize: '12px',
            },
            value: {
              // Custom formatter to display grade
              formatter: function () {
                return percent
              },
              offsetY: -10,
              color: 'var(--bs-gray-900)',
              fontSize: '24px',
              fontWeight: 'bold',
              show: true,
            },
          },

          track: {
            background: 'var(--bs-gray-500)',
            strokeWidth: '85%',
          },
        },
      },
      // Responsive configuration for different screen sizes
      responsive: [
        {
          breakpoint: 1300,
          options: {
            chart: {
              height: 150,
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  show: true,
                  name: {
                    offsetY: 10,
                    fontSize: '10px',
                  },
                  value: {
                    formatter: function () {
                      return percent
                    },
                    offsetY: -5,
                    fontSize: '14px',
                  },
                },
              },
            },
          },
        },
      ],
    }

    return (
      // Conditionally apply blur effect if no grade or user scan
      <div
        className='position-relative'
        style={!percent && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
      >
        <div ref={chartRef} id='chart1' />
      </div>
    )
  }
)

export default HalfCircleChart
