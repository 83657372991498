import React from 'react'
import Mark from '../../images/Mark'

interface Props {
  icon?: JSX.Element
  header?: string
  subHeader?: string
  bgColor?: string
  subColor?: string,
  children: React.ReactNode
  loading: boolean
}

/**
 * FPlus component displays a card-like structure with an icon, header, subheader, and children content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {JSX.Element} [props.icon=<i className='bi bi-bookmarks-fill fs-2x' />] - The icon to display in the card.
 * @param {string} [props.header=''] - The main header text.
 * @param {string} [props.subHeader=''] - The subheader text.
 * @param {string} [props.bgColor='bg-body'] - The background color of the card.
 * @param {string} [props.subColor=' '] - Additional color for the subheader.
 * @param {React.ReactNode} props.children - The content to display inside the card.
 * @param {boolean} props.loading - Indicates if the card is in a loading state.
 *
 * @example
 * <FPlus
 *   icon={<i className='bi bi-star-fill' />}
 *   header="Main Header"
 *   subHeader="This is a subheader"
 *   bgColor="bg-light"
 *   subColor="text-muted"
 *   loading={false}
 * >
 *   <p>Some additional content goes here.</p>
 * </FPlus>
 */
const FPlus = ({
  icon = <Mark className='text-gray-500 svg-icon-2x'/>,
  header = '',
  subHeader = '',
  bgColor = 'bg-body',
  subColor = ' ',
  loading,
  children
}: Props) => {
  return (
    <div className='d-flex flex-column bg-red-400 h-150px col-3'>
      <div className={`h-100px card card-body justify-content-between ${bgColor} ${loading && 'skeleton'}`}>
        <div className='d-flex flex-row row'>
          <div className='col-2'>
            {icon}
            <div className={`${subColor} fw-bolder fs-2 mt-5 mb-2`}>{header}</div>
            <div className='mt-5 pt-2 fw-bold text-gray-400'>{subHeader}</div>
          </div>

          <div className='d-flex justify-content-center align-items-center col-10'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FPlus