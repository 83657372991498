import React, {useState, useEffect, useLayoutEffect} from 'react'
import axios from 'axios'
import PageLoading from '../../loading/PageLoading'
import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {KTSVG} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import ConvertDate from '../../../../Utils/ConvertDate'
import {RootState} from '../../../../setup/redux/Store'
import {FilterSelectedSSL, SearchSSL, SetDataSSL, SortSSL} from '../../../modules/auth'
import {useIntl} from 'react-intl'
import useGetFilterOptionsDomains from '../../../../Utils/useGetFilterOptionsDomains'
import {SSL_DATA} from '../../../modules/auth/redux/DEMO_DATA'
import SearchAndSort from '../../components/SearchAndSort'
import useGetFilterOptionsSSL from '../../../../Utils/useGetFilterOptionsSSL'
import usePermission from '../../../../Utils/usePermission'
import {SelectedType} from '../../../modules/types/filters'
import Filters from '../Filters'
import {Button} from 'react-bootstrap'

/**
 * Options for sorting SSL data
 * @type {Array<{value: string, name: string}>}
 */
const SortOptions = [
  {value: 'name', name: 'name'},
  {value: 'grade', name: 'grade'},
  {value: 'timestamp', name: 'date'},
  {value: 'certificate_status', name: 'certificate'},
  {value: 'total_vulnerabilities', name: 'vulnerabilities'},
]

/**
 * Converts SSL grade to a corresponding color
 * @param {string} grade - The SSL grade
 * @returns {string} The color corresponding to the grade
 */
const colorConvertor = (grade: string) => {
  let color = ''
  color = grade === 'A' ? '#02694a' : color
  color = grade === 'A+' ? '#02694a' : color
  color = grade === 'B' ? '#255a8f' : color
  color = grade === 'C' ? '#9c8705' : color
  color = grade === 'D' ? '#75041e' : color
  color = grade === 'F' ? 'rgb(140 29 29)' : color
  color = grade === 'T' ? 'rgb(92 33 33)' : color
  color = grade === 'M' ? 'rgb(102 21 21)' : color
  color = grade === 'N' ? '#565672' : color

  return color
}

/**
 * SSLExposures component for displaying SSL data
 * @returns {JSX.Element}
 */
export const SSLExposures = () => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)
  const [showFilters, setShowFilters] = useState(false)
  const sslData = useSelector((state: RootState) => state?.root?.data?.dataSSL)
  const user = useSelector((state: RootState) => state?.root?.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const getFilterOptionsSSL = useGetFilterOptionsSSL()
  const intl = useIntl()
  const {isPermission, getPermission} = usePermission()
  const filterOptionSSL = useSelector((state: RootState) => state?.root?.filterOption?.ssl.options)
  const selected = useSelector((state: RootState) => state?.root?.filterOption?.ssl.selected)
  const sort = useSelector((state: RootState) => state.root.filterOption.ssl)
  const search = useSelector((state: RootState) => state.root.filterOption.ssl.search)

  /**
   * Creates the sorting props for the API request
   * @param {string} sortProps - The sorting property
   * @returns {string} The formatted sorting string for the API
   */
  function createPropsSort (sortProps: string) {
    if (sortProps) {
      return sortProps
    }
    if (sort.sortAsc) {
      return `&ordering=${sort.sortAsc}`
    } else if (sort.sortDes) {
      return `&ordering=${sort.sortDes}`
    }
    return ''
  }

  /**
   * Creates the search props for the API request
   * @returns {string} The formatted search string for the API
   */
  function createPropsSearch () {
    return search.map((item) => `&${item.name}=${item.value}`).join('')
  }

  /**
   * Handles page change in pagination
   * @param {Object} event - The page change event
   * @param {number} event.selected - The selected page number
   */
  const handlePageClick = (event: {selected: number}) => {
    fetchSslData({query: `?page=${event.selected + 1}`})
  }

  /**
   * Creates the filter props for the API request
   * @param {SelectedType | null} props - The filter properties
   * @returns {string} The formatted filter string for the API
   */
  function createPropsFilter (props?: SelectedType | null) {
    if (props) {
      const keys = Object.keys(props)
      const text = keys.map((key) => {
        if (props[key] && props[key].length) {
          return `&${key}=${props[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    } else {
      const keys = Object.keys(selected || {})
      const text = keys.map((key) => {
        if (selected?.[key] && selected[key].length) {
          return `&${key}=${selected[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    }
  }

  /**
   * Fetches SSL data from the API
   * @param {Object} options - The options for fetching data
   * @param {string} options.query - The query string
   * @param {SelectedType | null} options.filterProps - The filter properties
   * @param {string} options.sort - The sorting string
   */
  const fetchSslData = async ({
    query = '?page=1',
    filterProps = null,
    sort = '',
  }: {
    query?: string
    filterProps?: null | SelectedType
    sort?: string
  }) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/ssl/${query}${createPropsFilter(
          filterProps
        )}${createPropsSort(sort)}${createPropsSearch()}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataSSL(data.data))
      } else {
        console.error(
          `error in get data from ${
            process.env.REACT_APP_API_ENDPOINT
          }/ssl/${query}${createPropsFilter(filterProps)}${createPropsSort(
            sort
          )}${createPropsSearch()} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  /**
   * Handles the selection of filter items
   * @param {string} items - The filter category
   * @param {string} item - The selected filter item
   */
  function selectedHandel (items: string, item: string) {
    let copy = structuredClone(selected)
    if (copy?.[items] && copy[items].length && copy[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy?.[items]?.length) {
        copy[items].push(item)
      } else {
        if (copy) {
          copy[items] = [item]
        } else {
          copy = {[items]: [item]}
        }
      }
    }
    dispatch(FilterSelectedSSL(copy))
    if (process.env.REACT_APP_MODE !== 'demo') {
      fetchSslData({query: '?page=1', filterProps: copy})
    }
  }

  // Fetch SSL data and filter options on component mount
  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (sslData?.expireTime && sslData.expireTime >= nowDate) {
    } else {
      fetchSslData({})
    }
    if (!filterOptionSSL && process.env.REACT_APP_MODE !== 'demo') {
      getFilterOptionsSSL()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className={`w-100  p-4 bg-body overflow-hidden position-relative ${loading && 'skeleton'}`}
        style={{maxWidth: 'calc(100% - 100px)'}}
      >
        {isPermission()}
        <h1>{intl.formatMessage({id: 'SSL Report'})}</h1>
        <div className='position-relative d-flex pt-5 w-100'>
          <div className='w-100 ps-3'>
            <div className='d-flex flex-row row'>
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <SearchAndSort
                  className={'mb-5 mw-75'}
                  search={search}
                  setSearch={SearchSSL}
                  sort={sort}
                  setSort={SortSSL}
                  getData={fetchSslData}
                  sortOption={SortOptions}
                  searchOption={Object.keys(filterOptionSSL || {})}
                />
                <Button
                  className='mb-5'
                  onClick={() => {
                    setShowFilters(true)
                  }}
                >
                  Filter
                </Button>
              </div>
              <div className='w-100 h-100'>
                <div className={` h-100 bg-body rounded-bottom-0`}>
                  <div className='m-0 w-100 pe-3'>
                    <div className='d-flex justify-content-between align-items-center gap-5 bg-gray-400 mb-4 p-2 pt-1 pb-1 rounded-1 fw-bold w-100 fs-5'>
                      <div className='py-1 w-65px h-auto'>
                        <div
                          className='d-flex justify-content-start align-items-center h-auto pointer'
                          style={{width: 65}}
                        >
                          <span>{intl.formatMessage({id: 'Grade'})}</span>{' '}
                        </div>
                      </div>
                      <div className='py-1 w-100 h-auto'>
                        <div className='d-flex justify-content-start align-items-center w-100 h-auto pointer'>
                          <span>{intl.formatMessage({id: 'Name'})}</span>{' '}
                        </div>
                      </div>
                      <div className='d-flex py-1 w-75 h-auto'>
                        <div className='d-flex align-items-center w-100 h-auto pointer'>
                          {' '}
                          <span>{intl.formatMessage({id: 'Vulnerabilities'})}</span>{' '}
                        </div>
                      </div>
                      <div className='d-flex py-1 w-100 h-auto'>
                        <div className='d-flex align-items-center w-100 h-auto pointer'>
                          {' '}
                          <span className='text-nowrap'>
                            {intl.formatMessage({id: 'Certificate Status'})}
                          </span>{' '}
                        </div>
                      </div>
                      <div className='d-flex py-1 w-50 h-auto text-nowrap'>
                        {' '}
                        {intl.formatMessage({id: 'Expiry Date'})}{' '}
                      </div>
                      <div className='d-flex py-1 h-auto' style={{width: 130, minWidth: 130}}>
                        <div className='d-flex align-items-center w-100 h-auto pointer'>
                          {' '}
                          <span>{intl.formatMessage({id: 'Last Seen'})}</span>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end titles */}
                  <div className={`w-100 h-100 d-flex flex-column gap-3 overflow-auto pe-3 m-0`}>
                    {(!sslData?.data?.results?.length && !user?.is_user_scan
                      ? SSL_DATA.results
                      : sslData?.data?.results
                    )?.map((item, index: number) => {
                      const {
                        id,
                        grade,
                        host,
                        total_vulnerabilities,
                        timestamp,
                        timestamp_jalali,
                        certificate_status,
                        days_to_expiry,
                      } = item
                      return (
                        <div
                          style={!sslData?.data?.results?.length ? {filter: 'blur(3px)'} : {}}
                          onClick={() => {
                            if (sslData?.data?.results?.length)
                              navigate({
                                pathname: '/ssl-details',
                                search: `?id=${id}`,
                              })
                          }}
                          key={`ssl_list_${index}`}
                          className={`w-100 m-0  h-auto bg-gray-200 rounded-1 d-flex justify-content-center align-items-center pointer  hover_Costume overflow-hidden`}
                        >
                          <div className='d-flex justify-content-between align-items-center gap-5 p-2 pt-1 pb-1 w-100 overflow-hidden'>
                            <div className='position-relative d-flex align-items-center py-1 w-65px min-w-65px h-auto text-nowrap overflow-visible ssl_grade text_hide_width'>
                              {/* <div
                                className={`d-flex justify-content-center align-items-center position-relative`}
                              >
                                <KTSVG
                                  path='/media/hexagonal.svg'
                                  className='ms-2 svg-icon svg-icon-3x svg-icon-body'
                                  fill={colorConvertor(grade)}
                                />
                                <span className='position-absolute fw-bolder text-black ms-2'>
                                  {grade}
                                </span>
                              </div> */}
                              <div
                                className='position-absolute'
                                style={{
                                  backgroundColor: colorConvertor(grade),
                                  transform: 'rotate(35deg)',
                                  top: -28,
                                  left: -8,
                                  width: 40,
                                  height: 52,
                                }}
                              >
                                <span
                                  className='position-absolute text-white'
                                  style={{
                                    width: 24,
                                    top: 17,
                                    right: 4,
                                    transform: 'rotate(-35deg)',
                                    fontWeight: 'bolder',
                                    fontSize: 'large',
                                  }}
                                >
                                  {grade}
                                </span>
                              </div>
                            </div>
                            <div className='py-1 w-100 h-auto text-nowrap text_hide_width'>
                              {host}
                            </div>
                            <div className='d-flex justify-content-center py-1 w-75 min-w-129px h-auto text-nowrap text_hide_width'>
                              {total_vulnerabilities}
                            </div>
                            <div className='d-flex justify-content-center py-1 w-100 h-auto text-nowrap text_hide_width'>
                              {certificate_status ? 'Valid' : 'Invalid'}
                            </div>
                            <div className='d-flex justify-content-center py-1 w-50 h-auto text-nowrap pe-3 text_hide_width'>
                              <div className={`col-auto border border-0  bg-gray-200 p-0 m-0`}>
                                {days_to_expiry
                                  ? days_to_expiry + ' ' + intl.formatMessage({id: 'day'})
                                  : 'N/A'}
                              </div>
                            </div>
                            <div
                              className='d-flex py-1 w-130px min-w-130px h-auto text-nowrap text_hide_width'
                              style={{width: 130, minWidth: 130}}
                            >
                              <ConvertDate date={timestamp} jalali={timestamp_jalali} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end bg-body mt-5 pb-4 rounded-bottom pe-4'>
              <ReactPaginate
                nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={sslData?.data?.total_pages || 1}
                forcePage={sslData?.data?.current_page ? sslData?.data?.current_page - 1 : 0}
                previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </div>
      <Filters
        show={showFilters}
        setShow={setShowFilters}
        filterSelected={FilterSelectedSSL}
        selected={selected}
        get={fetchSslData}
        filterOptionDomains={filterOptionSSL}
      />
    </>
  )
}
